import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';


function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      <Link color="text.secondary" href="https://nyradar.in/">
        Nyradar
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <React.Fragment>
      <Divider />
      <Container
        sx={{
          // bgcolor: "lightgray",
          // bgcolor: "#f2f6fc",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // gap: { xs: 4, sm: 8 },
          // py: { xs: 8, sm: 10 },
          textAlign: { sm: 'center', md: 'left' },
        
        }}
      > 
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              minWidth: { xs: '100%', sm: '60%' },
            }}
          >
            <Copyright />
          </Box>
          
          
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Contact: financialwhispershq@gmail.com
            </Typography>
            
          </Box>
        </Box>
      
      </Container> 
    </React.Fragment>
  );
}
