import React, { useState } from 'react';

function WhatsInside({ htmlCode }) {
  
  return (
    <div>
        <h2>
            Key offerings of Financial Whispers
        </h2>
        <p>
            In the cacophony of the financial world, it&apos;s easy to get lost. Financial Whispers is here to cut through the noise and provide you with clear, actionable insights.
            <br />
            Financial Whispers is a comprehensive financial resource that aims to empower individuals with the knowledge and tools they need to make informed financial decisions. 
        </p>
        <p>
            <span style={{ color: 'SlateBlue', fontWeight: 'bold', marginRight: '10px' }}>
            Financially Fit:
            </span>
            Personal Finance tips and tricks to manage your money effectively, from budgeting to retirement planning.
        </p>
        <p>
            <span style={{ color: 'SlateBlue', fontWeight: 'bold', marginRight: '10px' }}>
            Fund Focus:
            </span>
            Explore the world of mutual funds, including fund selection, performance analysis, and risk management. 
        </p>
        <p>
            <span style={{ color: 'SlateBlue', fontWeight: 'bold', marginRight: '10px' }}>
            Investment Insights:
            </span>
            A broader overview of various investment strategies beyond value investing, such as growth investing, index investing, and more. 
        </p>
        <p>
            <span style={{ color: 'SlateBlue', fontWeight: 'bold', marginRight: '10px' }}>
            Mind Over Market:
            </span>
            Explore the psychological biases that can impact your investment decisions and how to overcome them.
        </p>
        <p>
            <span style={{ color: 'SlateBlue', fontWeight: 'bold', marginRight: '10px' }}>
            Statement Scanner:
            </span>
            Learn how to analyze financial statements to make informed investment decisions.
        </p>
        <p>
            <span style={{ color: 'SlateBlue', fontWeight: 'bold', marginRight: '10px' }}>
            Stock Market 101:
            </span>
            A beginner&apos;s guide to understanding the stock market, from IPOs to market indices.
        </p>
        <p>
            <span style={{ color: 'SlateBlue', fontWeight: 'bold', marginRight: '10px' }}>
            Value Investment:
            </span>
            A dedicated channel focusing on the principles and practices of value investing, including stock selection, valuation techniques, and portfolio management.
        </p> 
        <p>
            <span style={{ color: 'SlateBlue', fontWeight: 'bold', marginRight: '10px' }}>
            Vedic Wealth Wisdom:
            </span>
            Discover ancient Indian wisdom on wealth creation, prosperity, and financial well-being.
        </p>
        <p>
        Join us as we unravel the mysteries of the financial world, one whisper at a time.
        <br />
        Through insightful articles, expert analysis, and practical advice, Financial Whispers strives to demystify complex financial concepts and help readers make informed choices that align with their financial goals.
        </p>
      
    </div>
  );
}

export default WhatsInside;