import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
// import AppAppBar from './components/AppAppBar';
// import MainContent from './components/MainContent';
import AppContent from './components/AppContent';
// import Latest from './components/Latest';
import Footer from './components/Footer';
// import AppTheme from '../shared-theme/AppTheme';
import AppTheme from './shared-theme/AppTheme';

export default function Blog(props) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      {/* <AppAppBar /> */}
      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', my: 5, gap: 4 }}
      >
        {/* <MainContent /> */}
        <AppContent />
      </Container>
      {/* <Footer /> */}

    </AppTheme>
  );
}
