import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

function Quotes() {
  const [randomLine, setRandomLine] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch the text file and get a random line
  const fetchRandomLine = async () => {
    try {
      const response = await fetch('/data/quotes.txt'); // Replace with your file path
      if (!response.ok) {
        throw new Error('Failed to fetch the quotes');
      }
      
      const text = await response.text();
      const lines = text.split('\n').filter(line => line.trim() !== ''); // Split into lines and filter empty lines
      const randomIndex = Math.floor(Math.random() * lines.length); // Get random index
      setRandomLine(lines[randomIndex]); // Set the random line
    } catch (err) {
      setError(err.message); // Set the error message
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  // Fetch the random line when the component mounts
  useEffect(() => {
    fetchRandomLine();
  }, []); // Empty dependency array means this effect runs once on mount

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {/* <p>{randomLine}</p> */}
      <Typography variant="caption" display="block" component="div" sx={{fontStyle: 'italic'}}>
        {randomLine}
      </Typography>
    </div>
  );
}

export default Quotes;
