import * as React from 'react';
import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button"
import Modal from '@mui/material/Modal';
import ReactMarkdown from 'react-markdown';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Quotes from './Quotes';
import WhatsInside from './WhatsInside';
import Footer from './Footer';

import './AppContent.css'; // Import your CSS file

const SyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  height: '100%',
  backgroundColor: (theme.vars || theme).palette.background.paper,
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  '&:focus-visible': {
    outline: '3px solid',
    outlineColor: 'hsla(210, 98%, 48%, 0.5)',
    outlineOffset: '2px',
  },
}));

const SyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: 16,
  flexGrow: 1,
  '&:last-child': {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Default width to 80% of the screen width
  maxWidth: 800, // Max width of 600px for larger screens
  minWidth: 300, // Min width of 300px for smaller screens
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto', // Make sure content scrolls if it exceeds the modal's height
  height: 'auto', // Height is auto, will expand based on content
  '@media (max-width:600px)': {
    width: '90%', // For small screens (like mobile), set the width to 90%
    maxWidth: 'none', // Remove the maxWidth restriction
  }
};

const contentStyle = {
  maxHeight: '400px',   // You can adjust the height based on your design
  overflowY: 'auto',    // Enables scrolling if content exceeds maxHeight
  marginBottom: '1rem',
  paddingRight: '1rem',
};

function AppContent() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [focusedCardIndex] = useState(null);

  // Function to load articles data
  const loadArticles = async () => {
    try {
      const response = await fetch('/data/articles.json');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCardData(data); // Set the fetched card data
    } catch (err) {
      setError(err);
    }
  };

  // Function to load categories data
  const loadCategories = async () => {
    try {
      const response = await fetch('/data/categories.txt');
      const text = await response.text();
      const categoryList = text.split('\n').map(item => item.trim()).filter(Boolean);
      setCategories(categoryList); // Set the fetched categories
    } catch (err) {
      setError(err);
    }
  };

  // Function to handle the combined loading process of both articles and categories
  const loadData = async () => {
    setLoading(true); // Set loading state to true
    try {
      await loadCategories(); // Wait for categories to load first
      await loadArticles(); // Wait for articles to load after categories
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false); // Set loading state to false when done
    }
  };


  useEffect(() => {
    loadData(); // Call loadData once when the component mounts
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);// Empty dependency array means this effect runs only once when the component mounts

  useEffect(() => {
    if (cardData.length > 0 && categories.length > 0) {
      const currentPathname = window.location.pathname;
      const segments = currentPathname.split('/').filter(Boolean); // Split by '/' and remove empty segments

      if (segments.length === 0) {
        setCategory("All Categories");
      } else {
        if (categories.includes(segments[0])) {
          setCategory(segments[0]); // Set category from URL

          if (segments.length > 1) {
            // Find the matching card
            const cardValue = cardData.find(item => item.tag === segments[0] && item.id === segments[1]);
            if (cardValue) {
              setSelectedCard(cardValue);
              setOpenModal(true);
            }
          }
        } else {
          console.log('Invalid category specified in the URL');
          setCategory("All Categories");
        }
      }
    }
  }, [cardData, categories]); // This effect depends on cardData and categories after they are fetched

  // Optional: Render error message
  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  
  // Apply the filter only if category is not 'All Categories'
  /* eslint-disable react/jsx-key */
  const filteredData = category === 'All Categories'
  ? cardData  // Don't filter if filterUsername is 'All Categories'
  : cardData.filter((article) => article.tag !== 'All Categories' && article.tag.toLowerCase().includes(category.toLowerCase()));

  
  const level1Data = filteredData.length > 0 ? filteredData.slice(0,2) : filteredData;
  const level2Data = filteredData.length > 2 ? filteredData.slice(2,5) : [];
  const level3Data = filteredData.length > 5 ? filteredData.slice(5) : [];

  const StyledCard1 = ({ card, onClick }) => {
    return (
      <SyledCard
      variant="outlined"
      onClick={() => onClick(card)} sx={{ cursor: 'pointer', boxShadow: 3 }}
      tabIndex={0}
      className={focusedCardIndex === 0 ? 'Mui-focused' : ''}
    >
      <CardMedia
        component="img"
        alt="financial whispers"
        image={card.img}
        sx={{
          aspectRatio: '16 / 9',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      />
      <SyledCardContent>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography gutterBottom variant="caption" component="div">
          {card.tag}
        </Typography>
        <Typography variant="caption">{card.date}</Typography>
        </Box>
        <Typography gutterBottom variant="h6" component="div">
          {card.title}
        </Typography>
        <StyledTypography variant="body2" color="text.secondary" gutterBottom>
          {card.description}
        </StyledTypography>
      </SyledCardContent>
      
    </SyledCard>
    );
  };

  const StyledCard2 = ({ card, onClick }) => {
    return (
      <SyledCard
      variant="outlined"
      onClick={() => onClick(card)} sx={{ cursor: 'pointer', boxShadow: 3 }}
      tabIndex={0}
      className={focusedCardIndex === 0 ? 'Mui-focused' : ''}
    >
      
      <SyledCardContent>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography gutterBottom variant="caption" component="div">
          {card.tag}
        </Typography>
        <Typography variant="caption">{card.date}</Typography>
        </Box>
        <Typography gutterBottom variant="h6" component="div">
          {card.title}
        </Typography>
        <StyledTypography variant="body2" color="text.secondary" gutterBottom>
          {card.description}
        </StyledTypography>
      </SyledCardContent>
      
    </SyledCard>
    );
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCard(null);
  };

  function handleChange (event) {
    setCategory(event.target.value);
    window.history.pushState('state', 'Financial Whispers', window.location.origin)
  }


  return (
    <div className="App">
      <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
        {/* Your header content here */}
        {/* <h1>Fixed Header</h1> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <div>
            <Box sx={{ display: 'flex', alignItems: 'center'  }}>
              <Typography variant="h2" gutterBottom  sx={{ margin: 2 }}>
                Financial Whispers
              </Typography>
              <Typography variant="body1"  fontWeight="bold" color="DodgerBlue" sx={{ textAlign: 'right' }}>
                - Your Guide to Financial Serenity
              </Typography>
            </Box>
          </div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                minHeight: '20px',
                backgroundColor: 'LightGray',
                color: 'black',
                '&:hover': {
                  backgroundColor: 'Gray',
                  color: 'white',
                },
              }}
            >
              <Typography fontWeight="bold">Whispers of Wealth: What&apos;s Inside</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <WhatsInside />
            </AccordionDetails>
          </Accordion>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            width: '100%',
            justifyContent: 'space-between',
            alignItems: { xs: 'start', md: 'center' },
            gap: 4,
            overflow: 'auto',
          }}
        >
          
            <Box
              
              sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                gap: 3,
                overflow: 'auto',
                alignItems: 'center',
                // justifyContent: 'space-between',
                justifyContent: 'center',
              }}
            >
              
              <FormControl variant="standard" sx={{ m: 1, minWidth: 220, }}>
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={category}
                  label="Category"
                  onChange={handleChange}
                >
                  {/* {categories.map((category, index) => ( */}
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Selected Value: {category}</FormHelperText> */}
              </FormControl>

              <Typography> <Quotes /></Typography>
              
            </Box>
            
          </Box>
        </Box>
      </header>

      <main className="content">
        {/* Your scrollable content here */}
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
        {/* Add more content here to fill the scrollable area */}
        <Grid container spacing={2} columns={12}>
        
          {
            level1Data.map((card, index) => (
              <Grid size={{ xs: 12, md: 6 }} key={index}>
              <StyledCard1 card={card} onClick={handleCardClick} />
              </Grid>
          ))}
          {
            level2Data.map((card, index) => (
              <Grid size={{ xs: 12, md: 4 }} key={index}>
              <StyledCard1 card={card} onClick={handleCardClick} />
              </Grid>
          ))}
          {
            level3Data.map((card, index) => (
              <Grid size={{ xs: 12, md: 6 }} key={index}>
              <StyledCard2 card={card} onClick={handleCardClick} />
              </Grid>
          ))}

        </Grid>

        {/* Modal to show detailed content when a card is clicked */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="card-details-modal"
          aria-describedby="card-details-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography variant="h4" id="card-details-modal-title">
              {selectedCard?.title}
            </Typography>
            <Box sx={contentStyle}>height: 100vh;
              <ReactMarkdown>
              {selectedCard?.content}
              </ReactMarkdown>
            </Box>
            <Button variant="contained" color="primary" sx={{ mt: 3 }} onClick={handleCloseModal}>
              Close
            </Button>
          </Box>
        </Modal>
      </main>

      <footer className="footer">
        {/* Your footer content here */}
        {/* <p>Fixed Footer</p> */}
        <Footer />
      </footer>
    </div>
  );
}

export default AppContent;